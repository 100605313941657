import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Glide from "@glidejs/glide";

import SzopexGray from "../images/icons/clients/szopex-gray.svg";
import SzopexColor from "../images/icons/clients/szopex-color.svg";
import MKFreshGray from "../images/icons/clients/mkfresh-gray.svg";
import MKFreshColor from "../images/icons/clients/mkfresh-color.svg";
import IdeaBankGray from "../images/icons/clients/ideabank-gray.svg";
import IdeaBankColor from "../images/icons/clients/ideabank-color.svg";
import LoveReadingGray from "../images/icons/clients/lovereading-gray.svg";
import LoveReadingColor from "../images/icons/clients/lovereading-color.svg";
import AnswearGray from "../images/icons/clients/answear-gray.svg";
import AnswearColor from "../images/icons/clients/answear-color.svg";
import VeneziaGray from "../images/icons/clients/venezia-gray.svg";
import VeneziaColor from "../images/icons/clients/venezia-color.svg";
import ForFGray from "../images/icons/clients/forf-gray.svg";
import ForFColor from "../images/icons/clients/forf-color.svg";
import NocowanieGray from "../images/icons/clients/nocowanie-gray.svg";
import NocowanieColor from "../images/icons/clients/nocowanie-color.svg";
import IProspectGray from "../images/icons/clients/iprospect-gray.svg";
import IProspectColor from "../images/icons/clients/iprospect-color.svg";

class Testimonials extends Component {
  ssCarousel () {
    new Glide('.glide-ss', {
      animationDuration: 500,
      animationTimingFunc: 'ease-in-out',
      autoplay: false,
      bound: true,
      breakpoints: {
        770: {
          perView: 1
        },
        1408: {
          perView: 2
        }
      },
      hoverpause: false,
      keyboard: false,
      perView: 3,
      rewind: true,
      type: 'slider'
    }).mount();
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.ssCarousel();
    }
  }

  render () {
    return (
      <>
        <div className="section-h has-text-centered	has-text-left-desktop pad-t-3 mar-t-6 sr-hidden sr-1">
          <h2 className="section-h__2 mar-b-2">
            <FormattedMessage id="home.what_do_our_clients_say" />
          </h2>
        </div>
        
        <div className="section-ss columns is-centered is-multiline pad-t-1 mar-t-3">
          <div className="glide-ss glide">
            <div class="glide__track" data-glide-el="track">
              <div className="glide__slides">
                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <MKFreshGray />
                        <MKFreshColor />
                      </div>
                      <p>
                        <FormattedMessage id="home.mk_fresh_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Zbigniew Iwański
                        <span>E-commerce Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <IdeaBankGray />
                        <IdeaBankColor />
                      </div>
                      <p>
                        <FormattedMessage id="home.idea_bank_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Michał Węglewicz
                        <span>Performance Marketing Team Leader</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <LoveReadingGray />
                        <LoveReadingColor />
                      </div>
                      <p>
                        <FormattedMessage id="home.lovereading_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Peter Crawshaw
                        <span>Co-Founder and Director</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <AnswearGray style={{width: "158px"}} />
                        <AnswearColor style={{width: "158px"}} />
                      </div>
                      <p>
                        <FormattedMessage id="home.answear_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Marcin Nawrocki
                        <span>CRM Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <VeneziaGray style={{width: "170px"}} />
                        <VeneziaColor style={{width: "170px"}} />
                      </div>
                      <p>
                        <FormattedMessage id="home.venezia_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Przemysław Słaby
                        <span>eCommerce Marketing Director</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <ForFGray style={{width: "93px"}} />
                        <ForFColor style={{width: "93px"}} />
                      </div>
                      <p>
                        <FormattedMessage id="home.4f_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Tomasz Koźbiał
                        <span>eCommerce Director</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <NocowanieGray style={{width: "261px"}} />
                        <NocowanieColor style={{width: "261px"}} />
                      </div>
                      <p>
                        <FormattedMessage id="home.nocowanie_txt" />
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Paweł Jedynak
                        <span>Marketing Specialist</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-glide-el="controls">
              <button class="slider__arrow slider__arrow--prev glide__arrow glide__arrow--prev" data-glide-dir="<" />
              <button class="slider__arrow slider__arrow--next glide__arrow glide__arrow--next" data-glide-dir=">" />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Testimonials
